// i18next-extract-mark-ns-start qr-code-payment-restaurant
import React from 'react';
import {ContactSalesButton} from 'components/ContactSalesButton';
import {Content} from 'components/Content';
import {List} from 'components/List';
import {Section, SectionActions, SectionHeader, SectionImage} from 'components/Section';
import {SEO} from 'components/SEO';
import {
  GradientBox,
  GradientCtaImage,
  GradientSectionDescription
} from 'components/landings/GradientBox';
import {SignUpButton} from 'components/SignUpButton';
import {graphql} from 'gatsby';
import {BlogLink} from 'components/links/Blog';
import {StyledDiv} from 'components/StyledSx';
import {QrGroup} from 'components/monei-pay/QrGroup';
import {HorizontalScreenshots} from 'components/monei-pay/HorizontalScreenshots';
import {MainTitleContent} from 'components/monei-pay/MainTitleContent';
import {InternalPageLink} from 'components/links/Pages';
import {Trans, useI18next} from 'gatsby-plugin-react-i18next';
import {DownloadOnAppleStore, IosDownloadLink} from 'components/DownloadOnAppleStore';
import {LanguageEnum} from '../../../locales/types';
import {AndroidDownloadLink, DownloadOnGooglePlay} from 'components/DownloadOnGooglePlay';
import Bold from 'components/Bold';
import {MoneiVideoSection} from 'components/landings/MoneiVideoSection';
import {Flex} from 'components/Flex';
import IndexBackground from 'components/monei-pay/IndexBackground';
import 'keen-slider/keen-slider.min.css';
import integrated_payments_header_image from 'images/integrated_payments_header_image.png';
import {ButtonLink} from 'components/Button';
import {partnersLogos} from '../connect/index';
import {FaqsSection} from 'components/FaqsSection';
import {AnchorLink} from 'components/AnchorLink';
import {SupportLink} from 'components/links/Support';
import {TrustedBy} from 'components/TrustedBy';
import monei_pay_starbucks_1_en from '../../images/monei_pay_starbucks_1_en.png';
import monei_pay_starbucks_2_en from '../../images/monei_pay_starbucks_2_en.png';
import monei_pay_starbucks_3_en from '../../images/monei_pay_starbucks_3_en.png';
import monei_pay_starbucks_4_en from '../../images/monei_pay_4_en.png';
import monei_pay_starbucks_1_es from '../../images/monei_pay_starbucks_1_es.png';
import monei_pay_starbucks_2_es from '../../images/monei_pay_starbucks_2_es.png';
import monei_pay_starbucks_3_es from '../../images/monei_pay_starbucks_3_es.png';
import monei_pay_starbucks_4_es from '../../images/monei_pay_4_es.png';

const screenshots = {
  en: [
    monei_pay_starbucks_1_en,
    monei_pay_starbucks_2_en,
    monei_pay_starbucks_3_en,
    monei_pay_starbucks_4_en
  ],
  es: [
    monei_pay_starbucks_1_es,
    monei_pay_starbucks_2_es,
    monei_pay_starbucks_3_es,
    monei_pay_starbucks_4_es
  ]
};

const QrCodePaymentRestaurant: React.FC = () => {
  const {t, language} = useI18next();
  const scanToPay =
    language === LanguageEnum.en ? monei_pay_starbucks_2_en : monei_pay_starbucks_2_es;
  const faqs = [
    {
      header: t('What is QR code payment in restaurants?'),
      text: t(
        `QR code payment in restaurants is a cashless payment method that allows customers to make payments using their smartphones by scanning a digital QR code.`
      ),
      content: (
        <Trans>
          QR code payment in restaurants is a cashless payment method that allows customers to make
          payments using their smartphones by scanning a digital QR code.
        </Trans>
      )
    },
    {
      header: t('How does QR code payment work in restaurants with MONEI Pay?'),
      text: t(
        `When making a payment, customers open the camera on their smartphones, scan the digital QR code on your mobile device, choose their preferred payment method, and confirm the transaction.`
      ),
      content: (
        <Trans>
          When making a payment, customers open the camera on their smartphones, scan the digital QR
          code on your mobile device, choose their preferred payment method, and confirm the
          transaction.
        </Trans>
      )
    },
    {
      header: t('How can I start using MONEI Pay for QR code payments in my restaurant?'),
      text: t(
        `Go to dashboard.monei.com, sign up, complete your business details, and once you’ve been approved, you can start taking QR code payments in your restaurant. Accept the following payment methods with MONEI Pay: Cards, Bizum, Google Pay, and Apple Pay. If you want to add MONEI Pay to your existing MONEI account, contact support.`
      ),
      content: (
        <Trans>
          Go to <AnchorLink href="https://dashboard.monei.com/">dashboard.monei.com</AnchorLink>,
          sign up, complete your business details, and once you’ve been approved, you can start
          taking QR code payments in your restaurant. Accept the following payment methods with
          MONEI Pay: Cards, Bizum, Google Pay, and Apple Pay. If you want to add MONEI Pay to your
          existing MONEI account,{' '}
          <SupportLink slug="/requests/new?tf_4417061715857=%7B%7BAttributes.AccountId%7D%7D&tf_4451372321809=yes&tf_anonymous_requester_email=%7B%7BAddress%7D%7D&ticket_form_id=360000322338">
            contact support
          </SupportLink>
          .
        </Trans>
      )
    },
    {
      header: t('Is QR code payment secure for restaurant transactions?'),
      text: t(
        `Yes, QR code payment is generally considered secure. It uses encryption and tokenization methods to protect sensitive data and minimize the risk of fraud. But it's still essential to use trusted payment apps (like MONEI Pay) and ensure the security of your smartphone.`
      ),
      content: (
        <Trans>
          Yes, QR code payment is generally considered secure. It uses encryption and{' '}
          <BlogLink slug="what-is-tokenization-and-its-benefits-for-e-commerce">
            tokenization
          </BlogLink>{' '}
          methods to protect sensitive data and minimize the risk of fraud. But it's still essential
          to use trusted payment apps (like MONEI Pay) and ensure the security of your smartphone.
        </Trans>
      )
    },
    {
      header: t('What are the benefits of QR code payments for restaurants with MONEI Pay?'),
      text: t(
        `QR code payment offers several advantages for restaurants, including faster transactions, reduced cash handling, improved efficiency, and increased convenience for customers. It also enables contactless payments, which grew in popularity during the pandemic, and are here to stay.`
      ),
      content: (
        <Trans>
          QR code payment offers several advantages for restaurants, including faster transactions,
          reduced cash handling, improved efficiency, and increased convenience for customers. It
          also enables <BlogLink slug="contactless">contactless payments</BlogLink>, which grew in
          popularity during the pandemic, and are here to stay.
        </Trans>
      )
    },
    {
      header: t('Can any smartphone be used for QR code payments in restaurants?'),
      text: t(
        `Most modern smartphones with a built-in camera and internet connectivity can be used for QR code payments. But it’s important to make sure your smartphone has a compatible payment app installed — like MONEI Pay.`
      ),
      content: (
        <Trans>
          Most modern smartphones with a built-in camera and internet connectivity can be used for
          QR code payments. But it’s important to make sure your smartphone has a compatible payment
          app installed — like MONEI Pay.
        </Trans>
      )
    },
    {
      header: t(
        'Are there any fees associated with QR code payments for restaurants with MONEI Pay?'
      ),
      text: t(
        `Transaction fees depend on the payment method used by the end customer, there is no setup fee for MONEI Pay. Learn more about pricing.`
      ),
      content: (
        <Trans>
          Transaction fees depend on the payment method used by the end customer, there is no setup
          fee for MONEI Pay. Learn more about{' '}
          <InternalPageLink slug="pricing">pricing</InternalPageLink>.
        </Trans>
      )
    },
    {
      header: t(
        'Do I need additional hardware to accept QR code payments in my restaurant with MONEI Pay?'
      ),
      text: t(
        `QR code payments for restaurants via MONEI Pay do not require additional hardware. All you need is a mobile device to display the digital QR code that customers can scan using their smartphones.`
      ),
      content: (
        <Trans>
          QR code payments for restaurants via MONEI Pay do not require additional hardware. All you
          need is a mobile device to display the digital QR code that customers can scan using their
          smartphones.
        </Trans>
      )
    },
    {
      header: t('How many users can I add to my restaurant’s MONEI Pay account?'),
      text: t(`You can add as many additional users as you need for €0.16 per seat per day.`),
      content: (
        <Trans>You can add as many additional users as you need for €0.16 per seat per day.</Trans>
      )
    },
    {
      header: t('Can I issue refunds for restaurant QR code payments with MONEI Pay?'),
      text: t(`Yes. In the MONEI Pay app, you can issue full and partial refunds.`),
      content: (
        <Trans>
          Yes. In the MONEI Pay app, you can{' '}
          <SupportLink slug="/articles/8418026491537">issue full and partial refunds</SupportLink>.
        </Trans>
      )
    },
    {
      header: t('Are QR code payments widely accepted in restaurants?'),
      text: t(
        `QR code payments have gained significant popularity and acceptance in restaurants worldwide. Many establishments, ranging from small local eateries to large chain restaurants, now offer QR code payment options to provide customers with a more convenient and efficient payment experience.`
      ),
      content: (
        <Trans>
          QR code payments have gained significant popularity and acceptance in restaurants
          worldwide. Many establishments, ranging from small local eateries to large chain
          restaurants, now offer QR code payment options to provide customers with a more convenient
          and efficient payment experience.
        </Trans>
      )
    }
  ];
  const schemaFaqs = {
    '@type': 'FAQPage',
    mainEntity: faqs.map((faq) => ({
      '@type': 'Question',
      name: faq.header,
      acceptedAnswer: {
        '@type': 'Answer',
        text: faq.text
      }
    }))
  };
  return (
    <>
      <SEO
        title={t('QR Code Payment for Restaurants')}
        description={t(
          'Save time and money and improve the customer experience by accepting QR code payments in your restaurant. Get MONEI Pay ››'
        )}
        schema={schemaFaqs}
      />

      <IndexBackground>
        <Content>
          <Section columnLg sx={{paddingBottom: '0'}}>
            <MainTitleContent sx={{maxWidth: {all: '590px', lg: '100%'}}}>
              <SectionHeader underline tagName="h1">
                <Trans>QR code payments for restaurants: accept mobile payments today</Trans>
              </SectionHeader>
              <Trans parent="p">
                Let your customers pay quickly and easily from their smartphones by accepting QR
                code payments for restaurants from any mobile device. Save money on expensive
                restaurant POS hardware, improve the payment experience, and avoid running out of
                devices for your waitstaff. <Bold>Try MONEI Pay.</Bold>
              </Trans>
              <SectionActions>
                <SignUpButton variant="light" style={{marginRight: 15}}>
                  <Trans>Open an Account</Trans>
                </SignUpButton>
                <ContactSalesButton />
              </SectionActions>
            </MainTitleContent>
            <MoneiVideoSection />
          </Section>
          <TrustedBy clientList={partnersLogos} />
        </Content>
      </IndexBackground>
      <Content>
        <Section textAlign="center">
          <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
            <SectionHeader style={{fontSize: '2rem'}}>
              <Trans>How do QR code payments for restaurants work?</Trans>
            </SectionHeader>
            <Trans parent="p">
              <InternalPageLink slug="monei-pay">MONEI Pay</InternalPageLink> is a simple yet
              powerful solution that lets you accept{' '}
              <BlogLink slug="qr-code-payments">QR code payments</BlogLink> in your restaurant, bar,
              or cafe. All you have to do is open the app on your mobile device and add the payment
              amount. Once the digital QR payment code appears on the screen, the customer can scan
              it with their smartphone, select their preferred payment method, and complete the
              payment.
            </Trans>
            <SignUpButton variant="light">
              <Trans>Try MONEI Pay</Trans>
            </SignUpButton>
          </div>
        </Section>
      </Content>
      <Content fullWidth>
        <HorizontalScreenshots screenshots={screenshots} />
      </Content>
      <Content>
        <Section centered sx={{paddingTop: {all: '70px', md: '0', sm: '0', xs: '120px'}}}>
          <SectionImage
            style={{width: '455px'}}
            mobileWidth={370}
            src={scanToPay}
            alt="Scan to Pay"
            title="Scan to Pay"
            className="hide-on-mobile"
          />
          <div>
            <SectionHeader underline style={{marginTop: '10px'}}>
              <Trans>Improve the payment experience with QR code payments for restaurants</Trans>
            </SectionHeader>
            <Bold>
              <Trans parent="p">
                Unlike traditional restaurant POS systems, with{' '}
                <InternalPageLink slug="monei-pay">MONEI Pay</InternalPageLink> you can:
              </Trans>
            </Bold>
            <List>
              <Trans parent="li">
                Save money on hardware and{' '}
                <InternalPageLink slug="pricing">transaction fees</InternalPageLink> with a payment
                solution that’s 50% cheaper than traditional restaurant POS systems{' '}
              </Trans>
              <Trans parent="li">
                Accept{' '}
                <InternalPageLink slug="payment-methods">many payment methods</InternalPageLink>:{' '}
                <InternalPageLink slug="payment-methods/credit-cards">
                  credit cards
                </InternalPageLink>
                , <InternalPageLink slug="payment-methods/google-pay">Google Pay</InternalPageLink>,{' '}
                <InternalPageLink slug="payment-methods/apple-pay">Apple Pay</InternalPageLink>, and{' '}
                <InternalPageLink slug="payment-methods/bizum">Bizum</InternalPageLink> (in Spain){' '}
              </Trans>
              <Trans parent="li">
                Offer a <BlogLink slug="frictionless-payments">seamless payment</BlogLink>{' '}
                experience
              </Trans>
              <Trans parent="li">
                Equip your entire restaurant waitstaff with a{' '}
                <BlogLink slug="benefits-of-mobile-payments">mobile payment app</BlogLink> to
                process payments
              </Trans>
              <Trans parent="li">
                Let groups split the bill by creating multiple QR code payments
              </Trans>
            </List>
            <Bold>
              <Trans parent="p">
                👇 Accept QR code payments in your restaurant today — download the MONEI Pay app.
              </Trans>
            </Bold>
            <Flex>
              <div style={{marginRight: '16px'}}>
                <DownloadOnAppleStore />
              </div>
              <DownloadOnGooglePlay />
            </Flex>
          </div>
        </Section>
      </Content>

      <Content>
        <Section reverseOnMobile centered sx={{paddingTop: '40px'}}>
          <StyledDiv sx={{margin: {all: '-75px 30px 0', md: '0'}}}>
            <SectionHeader underline tagName="h3">
              <Trans>Start accepting restaurant QR code payments from your phone</Trans>
            </SectionHeader>
            <Trans parent="p">
              <Bold>Creating your MONEI Pay account is simple.</Bold> Open an account, then download
              the <AndroidDownloadLink>Android</AndroidDownloadLink> or{' '}
              <IosDownloadLink>iOS</IosDownloadLink> mobile payment app on as many devices as you
              need to start generating digital QR code payments for your restaurant.
            </Trans>
            <StyledDiv sx={{display: {all: 'inline', sm: 'none'}}}>
              <Trans parent="p">
                <Bold>Scan any QR code to test the payment experience.</Bold> 👉
              </Trans>
            </StyledDiv>
            <SignUpButton variant="light">
              <Trans>Open an Account</Trans>
            </SignUpButton>
          </StyledDiv>
          <QrGroup />
        </Section>
      </Content>
      <Content>
        <GradientBox wrapperStyle={{overflow: 'visible', marginTop: '60px'}}>
          <SectionHeader tagName="h3" underline style={{color: 'white'}}>
            <Trans>Do you have a platform or marketplace for restaurants, bars, and cafes?</Trans>
          </SectionHeader>
          <GradientSectionDescription>
            <Trans parent="p">
              Use MONEI Connect to integrate payments and help your users accept more payment
              methods in person or online.
            </Trans>
          </GradientSectionDescription>
          <SectionActions>
            <ButtonLink href="/connect/" variant="dark">
              <Trans>Discover MONEI Connect</Trans>
            </ButtonLink>
          </SectionActions>
          <GradientCtaImage
            sx={{
              maskImage: 'linear-gradient(180deg, #000000 94%, rgba(0, 0, 0, 0) 100%)',
              bottom: '0',
              right: '70px',
              width: '25%'
            }}
            src={integrated_payments_header_image}
          />
        </GradientBox>
      </Content>
      <Content>
        <Section centered column>
          <FaqsSection faqs={faqs} title={<Trans>QR code payment restaurant FAQ</Trans>} />
        </Section>
      </Content>
    </>
  );
};

export default QrCodePaymentRestaurant;

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(
      filter: {ns: {in: ["common", "qr-code-payment-restaurant"]}, language: {eq: $language}}
    ) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
